import { NavigateFunction, useNavigate } from "react-router";
import { routes } from "./routes";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { Subscription, User } from "./Models/MemberData";
import { FairExceptionResponse, FairResponseTyped } from "./Models/response";
import { DateTime } from "luxon";
import { Suspense, useContext, useState } from "react";
import { selectedSubscriptionNumberContext } from "../components/App";

/**
 * Returns the current user or redirects to the login page
 * @returns The currently signed in user. If not signed it, redirects to login page
 */
export function useUserAllowNull(): User | "loading" | null {
    const query = useQuery<User | null>({
        queryKey: ["user"],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<User> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<User> | FairExceptionResponse>("user", {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!result.data.success) {
                return null;
            }

            const subscriptions = result.data.data.subscriptions;
            const user: User = {
                ...result.data.data,
                subscriptions: Object.fromEntries(
                    Object.keys(subscriptions).map(subscriptionNumber => [
                        subscriptionNumber,
                        {
                            ...subscriptions[subscriptionNumber],
                            cancellationDate: subscriptions[subscriptionNumber].cancellationDate != null
                                ? DateTime.fromISO(subscriptions[subscriptionNumber].cancellationDate as any as string)
                                : null,
                            closeDate: subscriptions[subscriptionNumber].closeDate != null
                                ? DateTime.fromISO(subscriptions[subscriptionNumber].closeDate as any as string)
                                : null,
                            creationDate: subscriptions[subscriptionNumber].creationDate != null
                                ? DateTime.fromISO(subscriptions[subscriptionNumber].creationDate as any as string)
                                : null,
                            birthdate: subscriptions[subscriptionNumber].birthdate != null
                                ? DateTime.fromISO(subscriptions[subscriptionNumber].birthdate as any as string)
                                : null,
                        } as Subscription
                    ])
                )
            };
            return user;
        },
        keepPreviousData: true,
        retry: 0,
        onError: () => {
            return null;
        }
    });

    if (query.isLoading) {
        return "loading";
    }

    return query.data ?? null;
}

export function useUser(): User {
    return useUserAllowNull() as User;
}


export function signOut(queryClient: QueryClient) {
    localStorage.removeItem("token");
    localStorage.removeItem("selectedSubscriptionNumber");
    queryClient.setQueryData<User | null>(["user"], () => null);
    queryClient.invalidateQueries({ queryKey: ["user"] });
    queryClient.invalidateQueries({ queryKey: ["subscription"] });
    window.location.href = routes.login();
}

/**
 * returns the current subscription (or null if not signed in or loading)
 */
export function useCurrentSubscriptionAllowNull(): Subscription | null {
    const user = useUser();

    const selectedSubscriptionNumberCtx = useContext(selectedSubscriptionNumberContext);
    if (selectedSubscriptionNumberCtx === null)
    {
        return null;
    }
    const [selectedSubscriptionNumber, setSelectedSubscriptionNumber] = selectedSubscriptionNumberCtx;

    const subscriptionNumbers = Object.keys(user.subscriptions);
    if (subscriptionNumbers.length === 1) {
        if (selectedSubscriptionNumber !== subscriptionNumbers[0]) {
            localStorage.setItem("selectedSubscriptionNumber", subscriptionNumbers[0]);
            setSelectedSubscriptionNumber(subscriptionNumbers[0]);
        }
        return user.subscriptions[subscriptionNumbers[0]];
    }

    if (selectedSubscriptionNumber !== null && user.subscriptions[selectedSubscriptionNumber] !== undefined) {
        return user.subscriptions[selectedSubscriptionNumber];
    }

    return null;
}

export function useCurrentSubscription(): Subscription {
    return useCurrentSubscriptionAllowNull()!;
}

export function useSetCurrentSubscription(): (subscriptionNumber: string | null) => void {
    const selectedSubscriptionNumberCtx = useContext(selectedSubscriptionNumberContext);
    return (subscriptionNumber: string | null) => {
        if (selectedSubscriptionNumberCtx !== null) {
            const [selectedSubscriptionNumber, setSelectedSubscriptionNumber] = selectedSubscriptionNumberCtx;
            setSelectedSubscriptionNumber(subscriptionNumber);

            if (subscriptionNumber !== null) {
                localStorage.setItem("selectedSubscriptionNumber", subscriptionNumber);
            } else {
                localStorage.removeItem("selectedSubscriptionNumber");
            }
        }
    };
}