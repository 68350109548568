import * as React from "react";

interface CardProps {
    title?: string;
    className?: string;
    children: React.ReactNode
    content: boolean
}

export function Card (props: CardProps): React.ReactElement {
return <div className="card">
        <section className={"card-content" + (props.content ? " content" : "") + (props.className != undefined ? " " + props.className : "")}>
            { props.title && <h1 className="title is-3">{props.title}</h1> }
            { props.children }
        </section>
    </div>;
}
