import * as React from "react";

interface ILoadingSpinnerProps {
    loadingText?: string | undefined;
}

class LoadingSpinner extends React.Component<ILoadingSpinnerProps> {
    public render() {
        return <div>
            <div className="sk-folding-cube">
                <div className="sk-cube1 sk-cube"></div>
                <div className="sk-cube2 sk-cube"></div>
                <div className="sk-cube4 sk-cube"></div>
                <div className="sk-cube3 sk-cube"></div>
            </div>
            <p>{ this.props.loadingText !== undefined ? this.props.loadingText : "Vent Venligst" }</p>
        </div>;
    }
}

export default LoadingSpinner;
