import * as React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../ui/Card";
import { useSetCurrentSubscription, useUser } from "../../lib/authentication";
import { Subscription, User } from "../../lib/Models/MemberData";
import MemberStatus from "../../lib/Models/MemberStatus";
import { useNavigate } from "react-router";
import { routes } from "../../lib/routes";

export function PageSelectSubscription(): React.ReactElement {
    const { t } = useTranslation();
    const user = useUser();
    const setSubscription = useSetCurrentSubscription();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (Object.keys(user.subscriptions).length == 1) {
            navigate(routes.dashboard());
        }
    }, [user.subscriptions]);

    return <section className="container narrow section section-chose-member">
        <Card title={t("select_subscription.select_subscription")} content={true}>
            <p>{t("select_subscription.multiple_subscriptions", { phone: user.phoneNumber })}</p>
            <SubscriptionSelector user={user} />
        </Card>
    </section>;
}

function SubscriptionSelector(props: { user: User }): React.ReactElement {
    const { t } = useTranslation();
    const setSubscription = useSetCurrentSubscription();
    const navigate = useNavigate();
    const subscriptions = Object.keys(props.user.subscriptions)
        .map(subscriptionNumber => props.user.subscriptions[subscriptionNumber])
        .sort((a, b) => a.creationDate > b.creationDate ? 1 : -1);
        
    return <ul className="member-list">
        { subscriptions.map(subscription => {
            return <li key={subscription.subscriptionNumber}>
                <div className="member-information">
                    <strong>{subscription.name}</strong> <small>#{subscription.subscriptionNumber}</small>
                    <p>{statusText(subscription)}</p>
                </div>
                <div className="buttons">
                    <button className="button is-primary is-medium"
                        onClick={() => {
                            setSubscription(subscription.subscriptionNumber);
                            navigate(routes.dashboard())
                        }}>
                        {t("general.choose")}
                    </button>
                </div>
            </li>;
        }) }
    </ul>;

    function statusText(subscription: Subscription): string {
        switch (subscription.status) {
            case 1:
                return t("select_subscription.no_payment_info");
            case 2:
                if (subscription.cancellationDate !== null && subscription.closeDate !== null) {
                    return t("select_subscription.subscription_is_cancelled", {
                        date_cancelled: subscription.cancellationDate.toFormat("dd/MM-yyyy"),
                        date_close: subscription.closeDate.toFormat("dd/MM-yyyy") });
                }
                return t("select_subscription.subscription_is_active");
            case 3:
                return t("select_subscription.subscription_is_closed", { date: subscription.closeDate!.toFormat("dd/MM-yyyy") });
            default:
                return t("general.unknown_error");
        };
    }
}
