import * as React from "react";
import { Card } from "../ui/Card";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "../../lib/routes";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { Invoice, Payment, PaymentStatus } from "../../lib/Models/Payment";
import { DateTime } from "luxon";
import { PlaceholderTableRow } from "../general/PlaceholderTableRow";
import logo from "../../assets/fairfit-logo-black.svg";
import LoadingSpinner from "../general/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

export function PageInvoice(): React.ReactElement {
    const { t } = useTranslation();
    const { invoiceId, accessToken } = useParams();
    const { isLoading, data: invoice } = useQuery<Invoice | null>({
        queryKey: ["invoice", invoiceId, accessToken ?? ""],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<Invoice> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<Invoice> | FairExceptionResponse>("/invoice/" + invoiceId + "/" + (accessToken ?? ""), {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            });

            if (!result.data.success) {
                return null;
            }

            const payment = result.data.data;
            return {
                ...payment,
                invoiceDate: DateTime.fromISO(payment.invoiceDate as any as string),
            };
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    if (isLoading) {
        return <div className="section container">
            <Card title={t("payment.invoice", { invoice: invoiceId })} content={true}>
                <LoadingSpinner />
            </Card>
        </div>;
    }

    if (invoice === null || invoice === undefined) {
        return <div className="section container">
            <Card title={t("payment.invoice", { invoice: invoiceId })} content={true}>
                <p>{t("general.unknown_error")}</p>
            </Card>
        </div>;
    }

    return <div className="section container">
        <Card title={t("payment.invoice", { invoice: invoiceId })} className="print--hide" content={true}>
            <div className="buttons">
                <Link className="button" to={routes.paymentHistory()}>
                    {t("payment.back_to_overview")}
                </Link>
                <button className="button" onClick={() => window.print()}>
                    <span className="icon is-small"><FontAwesomeIcon icon={faPrint} /></span>
                    <span>{t("payment.print")}</span>
                </button>
            </div>
        </Card>
        <InvoiceComponent invoice={invoice} />
    </div>;
}

function InvoiceComponent(props: { invoice: Invoice }): React.ReactElement {
    const { t } = useTranslation();

    return <Card content={false} className="invoice">
        <div className="invoice--logo">
            <p>{t("general.fair_fitness")}</p>
            <img src={logo} />
        </div>

        <div className="invoice--from-to">
            <div className="invoice--customer-details">
                <p>{props.invoice.customerName}</p>
                <p>{props.invoice.customerAddress}</p>
                <p>{props.invoice.customerPostalCode}</p>
                <p>{t("payment.denmark")}</p>
            </div>
            <div className="invoice--company-details">
                <p>{props.invoice.companyName}</p>
                <p>{t("payment.cvr_value", { cvr: props.invoice.companyCvr })}</p>
                <p>{props.invoice.companyAddress}</p>
                <p>{props.invoice.companyPostalCode} {props.invoice.companyCity}</p>
                <p>{t("payment.denmark")}</p>
            </div>
        </div>

        <div className="invoice--section-divider">
            <p>{t("payment.invoice")}</p>
        </div>

        <div className="invoice--invoice-details">
            <table>
                <tbody>
                    <tr>
                        <td><span>{t("payment.invoice_number")}</span></td>
                        <td>{props.invoice.invoiceNumber}</td>
                    </tr>
                    <tr>
                        <td><span>{t("payment.invoice_date")}</span></td>
                        <td>{props.invoice.invoiceDate.toFormat("dd.MM.yyyy")}</td>
                    </tr>
                    <tr>
                        <td><span>{t("payment.customer_number")}</span></td>
                        <td>{props.invoice.subscriptionNumber}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="invoice--invoice-lines">
            <table>
                <thead>
                    <tr>
                        <th>{t("payment.line_text")}</th>
                        <th>{t("payment.line_quantity")}</th>
                        <th>{t("payment.line_amount_unit")}</th>
                        <th>{t("payment.line_unit_price")}</th>
                        <th>{t("payment.line_total_price")}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.invoice.lines.map((line, i) => <tr key={i}>
                        <td>{line.description}</td>
                        <td>{line.quantity}</td>
                        <td>{t("payment.line_amount_unit_pcs")}</td>
                        <td>{(line.unitAmountExcludingVat / 100).toFixed(2)}</td>
                        <td>{(line.amountExcludingVat / 100).toFixed(2)}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>

        <div className="invoice--totals">
            <table>
                <tbody>
                    <tr>
                        <td>{t("payment.subtotal")}</td>
                        <td>{((props.invoice.amountExcludingVat ?? 0) / 100).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>{t("payment.vat_amount", { vat: "25" })}</td>
                        <td>{((props.invoice.amountVat ?? 0) / 100).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>{t("payment.total_dkk")}</td>
                        <td>{((props.invoice.amountIncludingVat ?? 0) / 100).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>{t("payment.invoice_is_paid")}</p>
    </Card>;
}