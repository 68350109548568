import ICenterData from "./CenterData";
import { Payment } from "./Payment";

export enum ExceptionType {
    TokenCreationError,
    TokenNotFound,
    TokenExpired,
    MemberConfirmationExpired,
    MemberAlreadyConfirmed,
    ConfirmationError,
    InvalidKeyOrEndpoint,
    InputException,
    MemberNotFound,
    TokenBlockedOverAccess,
    TokenRecentlySent,
    InvalidParent,
    ParentLinkRequired,
    MemberTooYoung,
    ParentLinkAlreadyExists,
    MemberIsAlreadyCancelled,
    InvalidMember,
    UnauthorizedAction,
}

export interface FairResponse {
    success: true;
}

export interface FairResponseTyped<T> extends FairResponse {
    data: T;
}

export interface FairExceptionResponse {
    success: false;
    exception: FairFairControlException;
}

export interface FairFairControlException {
    type: ExceptionType;
    message: string;
}
