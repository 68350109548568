import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import axios from "axios";
import { ExceptionType, FairExceptionResponse, FairResponse, FairResponseTyped } from "../../lib/Models/response";
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routes } from "../../lib/routes";
import { useNavigate } from "react-router";
import { signOut, useUser, useUserAllowNull } from "../../lib/authentication";
import { Card } from "../ui/Card";
import { Link } from "react-router-dom";

export default function PageLogin(): React.ReactElement {
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");
    const [smsState, setSmsState] = React.useState<"not-sent" | "sent">("not-sent");
    const [sendSmsError, setSendSmsError] = React.useState<string | null>(null);
    const { t } = useTranslation();
    const user = useUserAllowNull();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user !== "loading" && user !== null) {
            navigate(routes.dashboard());
        }
    }, [user]);
    
    const sendSmsMutation = useMutation({
        mutationFn: () => {
            return axios.post<FairResponse | FairExceptionResponse>("smstoken/" + phoneNumber);
        },
        onSuccess: data => {
            if (data.data.success) {
                setSendSmsError(null);
                setSmsState("sent");
            } else {
                switch (data.data.exception.type) {
                    case ExceptionType.MemberNotFound:
                        setSendSmsError(t("login.subscription_not_found"));
                        break;
                    default:
                        setSendSmsError(t("general.unknown_error"));
                }
            }
        },
        onError: () => {
            setSendSmsError(t("general.unknown_error"));
        },
    });

    return <section className="section container narrow">
        <Card title={t("general.customer_portal")} content={true}>
            <p>{t("login.welcome_to_customer_portal")}</p>
            <p>{t("login.all_members_have_access_enter_phone")}
            </p>
            <br />

            { /* Phone number field */ }
            <div className="field">
                <label className="label">{ t("general.phone_number") }</label>
                <div className="field is-expanded">
                    <div className="field has-addons">
                        <p className="control">
                            <a className="button is-static">{ t("general.phone_dk_prefix") }</a>
                        </p>
                        <p className="control is-expanded">
                            <input type="text"
                                className={"input" + (sendSmsError !== null ? " is-danger" : "")}
                                onChange={e => setPhoneNumber(e.target.value.replace(/[^0-9]/g, ""))}
                                value={phoneNumber}
                                disabled={smsState !== "not-sent" || sendSmsMutation.isLoading}
                                onKeyDown={e => e.key === "Enter" && sendSmsMutation.mutate()} />
                        </p>
                        <p className="control">
                            <button className="button is-primary"
                                disabled={sendSmsMutation.isLoading}
                                onClick={() => smsState === "not-sent" ? sendSmsMutation.mutate() : setSmsState("not-sent")}>
                                {smsState == "not-sent" ? t("general.continue") : t("general.change") }
                            </button>
                        </p>
                    </div>
                </div>
                {sendSmsError !== null && <p className="help is-danger">{sendSmsError}</p>}
            </div>

            {smsState === "sent" && <EnterCodeSection phoneNumber={phoneNumber} />}
        </Card>
    </section>;
}

function EnterCodeSection(props: { phoneNumber: string }): React.ReactElement {
    const [validateTokenState, setValidateTokenState] = React.useState<"pending" | "validated">("pending");
    const [validateTokenError, setValidateTokenError] = React.useState<string | null>(null);
    const [tokenValue, setTokenValue] = React.useState<string>("");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const authenticateMutation = useMutation({
        mutationFn: () => {
            return axios.post<FairResponseTyped<string> | FairExceptionResponse>("authenticate", {
                phoneNumber: props.phoneNumber,
                token: tokenValue
            });
        },
        onSuccess: data => {
            if (data.data.success) {
                setValidateTokenError(null);
                setValidateTokenState("validated");
                localStorage.setItem("token", data.data.data);
                queryClient.invalidateQueries({ queryKey: ["user"] })

                setTimeout(() => {
                    navigate(routes.dashboard());
                }, 1000);
            } else {
                switch (data.data.exception.type) {
                    case ExceptionType.TokenNotFound:
                        setValidateTokenError(t("login.invalid_token"));
                        break;
                    case ExceptionType.TokenExpired:
                        setValidateTokenError(t("login.token_expired"));
                        break;
                    default:
                        setValidateTokenError(t("general.unknown_error"));
                }
            }
        },
        onError: () => {
            setValidateTokenError(t("general.unknown_error"));
        },
    });

    return <>
        <br />
        <p>{t("login.code_sent_to", { phone: props.phoneNumber })}</p>
        <p>{t("login.code_not_received", { minutes: 10 })}</p>
        <p>
            <Trans i18nKey={"general.contact_us_on_if_problem_persists"}>
                .<Link to="https://fairfit.dk/kontakt" target="_blank">
                    <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span>.</span>
                </Link>
            </Trans>
        </p>
        <br />

        { /* Code field */ }
        <div className="field">
            <label className="label">{ t("login.code") }</label>
            <div className="field-body">
                <div className="field has-addons">
                    <p className="control has-icons-left is-expanded">
                        <input type="text"
                            className={"input"
                                + (validateTokenError !== null ? " is-danger" : "")
                                + (validateTokenState === "validated" ? " is-success" : "")}
                            onChange={e => setTokenValue( e.target.value )}
                            value={tokenValue}
                            disabled={authenticateMutation.isLoading}
                            onKeyDown={e => e.key === "Enter" && authenticateMutation.mutate()} />
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={faKey} />
                        </span>
                    </p>
                    <p className="control">
                        <button className="button is-primary"
                            disabled={authenticateMutation.isLoading}
                            onClick={() => authenticateMutation.mutate()}>
                            { t("general.continue") }
                        </button>
                    </p>
                </div>
            </div>
            { validateTokenError !== null &&
                <p className="help is-danger">
                    { validateTokenError }
                </p>
            }
        </div>
    </>;
}