import { DateTime } from "luxon";

export interface Payment {
    reference: string,
    invoiceId: number | null,
    amount: number,
    amountExclVat: number,
    amountVat: number
    capturedDateTime: DateTime | null,
    createdDateTime: DateTime,
    status: PaymentStatus,
    orderLines: OrderLine[]
}

export interface OrderLine {
    text: string,
    amount: number,
    amountExclVat: number,
    amountVat: number
}

export enum PaymentStatus {
    Captured,
    Pending,
    Failed,
    Cancelled
}

export interface Invoice {
    id: number,
    invoiceDate: DateTime,
    invoiceNumber: number,
    subscriptionNumber: number,
    customerName: string,
    customerAddress: string,
    customerPostalCode: string,
    companyCvr: string,
    companyName: string,
    companyAddress: string,
    companyPostalCode: string,
    companyCity: string,
    amountIncludingVat: number,
    amountExcludingVat: number,
    amountVat: number,
    lines: {
        amountIncludingVat: number,
        amountExcludingVat: number,
        amountVat: number,
        unitAmountIncludingVat: number,
        unitAmountExcludingVat: number,
        quantity: number,
        vatPercentage: number,
        description: string
    }[]
}