import * as React from "react";
import { useCurrentSubscription } from "../../lib/authentication";
import { Card } from "../ui/Card";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { Trans, useTranslation } from "react-i18next";
import LoadingSpinner from "../general/LoadingSpinner";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { DateTime } from "luxon";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PageUpdateCard(): React.ReactElement {
    const { t } = useTranslation();

    return <div className="section container">
        <Card title={t("payment.change_card")} content={true}>
            <UpdateCardComponent />
            <div className="buttons">
                <Link className="button" to={routes.dashboard()}>{t("general.back_to_front_page")}</Link>
            </div>
        </Card>
    </div>;
}

function UpdateCardComponent(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const reepayWindowRef = React.useRef(null);
    const [reepayObject, setReepayObject] = React.useState<any>(null);
    const [paymentChanged, setPaymentChanged] = React.useState(false);
    const { t } = useTranslation();

    const { isLoading: isLoadingCardSession, data } = useQuery<{ sessionId: string, dueAmount: number } | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "charge-session"],
        queryFn: async () => {
            if (subscription.closeDate !== null && subscription.closeDate < DateTime.now()) {
                return null;
            }

            let result: AxiosResponse<FairResponseTyped<{ sessionId: string, dueAmount: number }> | FairExceptionResponse, any>
            result = await axios.post<FairResponseTyped<{ sessionId: string, dueAmount: number }> | FairExceptionResponse>("subscription/" + subscription.subscriptionNumber + "/chargesession", {}, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!result.data.success) {
                return null;
            }

            return result.data.data;
        },
        keepPreviousData: true,
        retry: 0,
        onError: () => {
            return null;
        }
    });
    const chargeSessionId = data?.sessionId ?? null;
    const dueAmount = data?.dueAmount ?? null;

    React.useEffect(() => {
        if (chargeSessionId !== null) {
            if (reepayObject !== null) {
                try {
                    (reepayObject as any).destroy();
                } catch { }
            }

            const newReepayObject = new (window as any).Reepay.EmbeddedSubscription(chargeSessionId, { html_element: 'reePayWindow' });
            newReepayObject.addEventHandler((window as any).Reepay.Event.Accept, () => {
                if (reepayObject !== null)
                {
                    reepayObject.removeEventHandler((window as any).Reepay.Event.Accept);
                    reepayObject.destroy();
                }
                setPaymentChanged(true);
            });
            newReepayObject.addEventHandler((window as any).Reepay.Event.Error, (event: any) => {
                console.log(event);
            });
            setReepayObject(newReepayObject);
        }
    }, [chargeSessionId])

    if (subscription.closeDate !== null && subscription.closeDate < DateTime.now()) {
        return <p>{t("payment.subscription_is_closed", { date: subscription.closeDate.toFormat("dd/MM-yyyy")})}</p>
    }

    if (isLoadingCardSession) {
        return <LoadingSpinner />;
    }

    if (paymentChanged) {
        return <p>{t("payment.payment_info_was_updated")}</p>
    }

    if (chargeSessionId === null) {
        return <p>
            <Trans i18nKey={"general.unknown_error_contact_us"}>
                .<Link to={routes.messages()}>
                    <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span>.</span>
                </Link>
            </Trans>
        </p>;
    }

    return <>
        <p>{t("payment.enter_payment_below")}</p>
        {dueAmount !== null && dueAmount !== undefined && <p>{t("payment.additional_charges_other_than_subscription", { amount: (dueAmount / 100).toFixed(2) })}</p>}
        <div id="reePayWindow" ref={reepayWindowRef}></div>
    </>;
}