import * as React from "react";
import { Card } from "../ui/Card";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useCurrentSubscription } from "../../lib/authentication";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { Letter } from "../../lib/Models/MemberData";
import { PlaceholderTableRow } from "../general/PlaceholderTableRow";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";

export function PageMessages(): React.ReactElement {
    const { t } = useTranslation();
    return  <div className="section container narrow">
        <Card title={t("dashboard.contact_us")} content={true}>
            <a href="https://fairfit.dk/kontakt">
                <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                {t("dashboard.contact_us")}
            </a>
        </Card>
        <Card title={t("messages.messages_from_fairfit")} content={true}>
            <FairfitMessageTable />
        </Card>
    </div>;
}

function FairfitMessageTable(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const { t } = useTranslation();
    const { isLoading, data: letters } = useQuery<Letter[] | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "letter"],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<Letter[]> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<Letter[]> | FairExceptionResponse>("/subscription/" + subscription.subscriptionNumber + "/letter", {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            });

            if (!result.data.success) {
                return null;
            }

            const letters = result.data.data;
            return letters.map(letter => ({
                ...letter,
                sentDateTime: DateTime.fromISO(letter.sentDateTime as any as string),
            }));
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    return <div className="table-container">
        <table className="table is-fullwidth">
            <thead>
                <tr>
                    <th>{t("messages.date_sent")}</th>
                    <th>{t("messages.description")}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {letters === undefined || letters === null
                    ? <>
                        <PlaceholderTableRow columns={3} type={"td"} />
                        <PlaceholderTableRow columns={3} type={"td"} />
                        <PlaceholderTableRow columns={3} type={"td"} />
                        <PlaceholderTableRow columns={3} type={"td"} />
                        <PlaceholderTableRow columns={3} type={"td"} />
                    </>
                    : letters.map(letter => <tr key={letter.type + "-" + letter.id}>
                        <td>{letter.sentDateTime.toFormat("dd/MM-yyyy")}</td>
                        {/* i18next-extract-disable-next-line */}
                        <td>{t("messages." + letter.type.toLowerCase())}</td>
                        <td>
                            <Link to={routes.message(letter.type + "-" + letter.id)}>
                                <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                                {t("messages.view_letter")}
                            </Link>
                        </td>
                    </tr>)}
            </tbody>
        </table>
    </div>;
}