import * as React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { Card } from "../ui/Card";
import { useTranslation } from "react-i18next";
import { useCurrentSubscription } from "../../lib/authentication";
import { Subscription } from "../../lib/Models/MemberData";
import { CancellationLetter } from "../general/CancellationLetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCreditCardAlt, faEnvelope, faExclamationTriangle, faReceipt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairResponseTyped, FairExceptionResponse } from "../../lib/Models/response";

export function Dashboard(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const { t } = useTranslation();

    return <section className="section container">
        <div className="columns">
            <section className="column is-two-thirds">
                <Card title={t("general.my_fairfit")} content={true}>
                    <p>
                        {t("dashboard.welcome")}
                    </p>
                    <SubscriptionStatus />
                </Card>
                {!subscription.hasOnDemandMembership && <CancellationLetterCard />}
            </section>
            <section className="column">
                <Card title={t("general.actions")} content={true}>
                    <p className="block">
                        <Link to={routes.paymentHistory()}>
                            <span className="icon"><FontAwesomeIcon icon={faReceipt} /></span>
                            {t("dashboard.see_payment_overview")}
                        </Link>
                    </p>
                    <p className="block">
                        <Link to={routes.updateCard()}>
                            <span className="icon"><FontAwesomeIcon icon={faCreditCardAlt} /></span>
                            {t("dashboard.change_payment_info")}
                        </Link>
                    </p>
                    <p className="block">
                        <Link to={routes.cancel()}>
                            <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>
                            {t("dashboard.cancel_subscription")}
                        </Link>
                    </p>
                    <p className="block">
                        <Link to={routes.messages()}>
                            <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                            {t("dashboard.contact_us")}
                        </Link>
                    </p>
                </Card>
            </section>
        </div>
    </section>;
}

function SubscriptionStatus(): React.ReactElement {
    const { t } = useTranslation();
    const subscription = useCurrentSubscription();

    const { isLoading, data: dueAmount } = useQuery<number | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "due-amount"],
        suspense: true,
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<number> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<number> | FairExceptionResponse>("/subscription/" + subscription.subscriptionNumber + "/dueamount", {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            });

            if (!result.data.success) {
                return null;
            }

            return result.data.data;
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    if (subscription === null || dueAmount === null || dueAmount === undefined) return <div></div>;

    switch (subscription.status) {
        // TicketPending.
        case 1:
            return <div>
                <p>
                    <span className="icon has-text-danger"><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                    <span className="has-text-weight-bold"><span className="has-text-danger">{t("dashboard.warning")}</span> {t("dashboard.missing_payment")}</span>
                    <br />
                    {t("dashboard.no_payment_info")}
                </p>
                <div className="buttons">
                    <Link to={routes.updateCard()} className="button is-primary">{t("general.pay_now")}</Link>
                    <Link to={routes.paymentHistory()} className="button">{t("payment.payment_overview")}</Link>
                </div>

                <CancellationText subscription={subscription} />
            </div>;
        case 2:
            if (dueAmount > 0) {
                return <div>
                    <p>
                        <span className="icon has-text-danger"><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                        <span className="has-text-weight-bold">
                            <span className="has-text-danger">{t("dashboard.warning")}</span> {t("dashboard.missing_payment_arrears", { amount: (dueAmount / 100).toFixed(2) })}
                        </span>
                        {subscription.accessBlockedDunning && <>
                            <br />
                            <span className="icon has-text-danger"><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                            <span className="has-text-weight-bold">
                                {t("dashboard.access_blocked_dunning")}
                            </span>
                        </>}
                        <br />
                    </p>
                    <div className="buttons">
                        <Link to={routes.updateCard()} className="button is-primary">{t("general.pay_now")}</Link>
                        <Link to={routes.paymentHistory()} className="button">{t("payment.payment_overview")}</Link>
                    </div>

                    <CancellationText subscription={subscription}/>
                </div>;
            }
            
            if (subscription.accessBlockedBioCam) {
                return <div>
                    <p>
                        <span className="icon has-text-danger"><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                        <span className="has-text-weight-bold">
                            <span className="has-text-danger">{t("dashboard.warning")}</span> {t("dashboard.access_blocked_terms_violation")}
                        </span>
                        <br />
                    </p>
                    { subscription.bioCamLetterReference != "" && <div className="buttons">
                        <Link to={routes.message(subscription.bioCamLetterReference)} className="button">{t("general.see_more")}</Link>
                    </div>}

                    <CancellationText subscription={subscription}/>
                </div>;
            }

            return <div>
                <p>
                    <span className="icon has-text-success"><FontAwesomeIcon icon={faCheck} /></span>
                    {t("dashboard.subscription_is_active")}
                </p>

                <CancellationText subscription={subscription}/>
            </div>;
        case 3:
            return <div>
                { t("dashboard.subscription_is_closed", { date: subscription.closeDate!.toFormat("dd/MM-yyyy") })}
            </div>;
        default:
            return <div>{ t("general.unknown_error") }</div>
    };
}

function CancellationText(props: { subscription: Subscription }): React.ReactElement | null {
    const { t } = useTranslation();
    if (props.subscription.cancellationDate !== null) {
        if (props.subscription.hasOnDemandMembership) {
            return <p>
                {t("dashboard.subscription_is_cancelled_ondemand", {
                    date_cancelled: props.subscription.cancellationDate.toFormat("dd/MM-yyyy"),
                    date_close: props.subscription.closeDate!.toFormat("dd/MM-yyyy")
                }) }
            </p>;
        }
        return <p>
            {t("dashboard.subscription_is_cancelled", {
                date_cancelled: props.subscription.cancellationDate.toFormat("dd/MM-yyyy"),
                date_close: props.subscription.closeDate!.toFormat("dd/MM-yyyy")
            }) }
        </p>;
    }
    return null;
}

function CancellationLetterCard(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const { t } = useTranslation();
    
    if (subscription.cancellationDate === null) {
        return <></>;
    }
    
    return <Card title={t("dashboard.cancellation_letter")} content={false}>
        <CancellationLetter />
    </Card>
}