import * as React from "react";
import { useCurrentSubscription } from "../../lib/authentication";
import { Card } from "../ui/Card";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { DateTime } from "luxon";
import { Subscription } from "../../lib/Models/MemberData";
import { CancellationLetter } from "../general/CancellationLetter";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PageCancel(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const [showSurvey, setShowSurvey] = React.useState(false);
    const [subscriptionConfirmed, setSubscriptionConfirmed] = React.useState(false);
    const { t } = useTranslation();

    return  <div className="section container narrow">
        <Card title={t("cancel_subscription.cancel_subscription")} content={true}>
            {content()}
        </Card>
    </div>;

    function content(): React.ReactElement | null{
        if (subscription.closeDate !== null) {
            return <>
                <CancelledConfirmation subscription={subscription} showSurvey={showSurvey} />
                <div className="buttons">
                    <Link className="button" to={routes.dashboard()}>{t("general.back_to_front_page")}</Link>
                </div>
            </>
        }
        if (!subscriptionConfirmed) {
            return <CancelSubscriptionConfirmation subscription={subscription} onConfirm={() => setSubscriptionConfirmed(true)} />
        }
        return <Cancellation onCancelled={() => setShowSurvey(true)} />;
    }
}

function CancelSubscriptionConfirmation(props: { subscription: Subscription, onConfirm: () => void }): React.ReactElement {
    const { t } = useTranslation();
    return <>
        <p>
            {t("cancel_subscription.you_are_cancelling")}
        </p>
        <div className="table-container">
            <table className="table is-striped is-fullwidth">
                <tbody>
                    <tr>
                        <td>{t("general.name")}</td>
                        <td>{props.subscription.name}</td>
                    </tr>
                    <tr>
                        <td>{t("general.subscription_number")}</td>
                        <td>{props.subscription.subscriptionNumber}</td>
                    </tr>
                    <tr>
                        <td>{t("general.birthdate")}</td>
                        <td>{props.subscription.birthdate?.toFormat("dd/MM-yyyy")}</td>
                    </tr>
                    <tr>
                        <td>{t("general.address")}</td>
                        <td>{props.subscription.address}</td>
                    </tr>
                    <tr>
                        <td>{t("cancel_subscription.main_center")}</td>
                        <td>{props.subscription.centerName}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>{t("general.are_you_sure")}</p>
        <div className="buttons">
            <button className={"button is-danger"}
                onClick={() => props.onConfirm()}>
                {t("cancel_subscription.i_wish_to_cancel")}
            </button>
            <Link to={routes.dashboard()}>
                <button className="button is-success">
                    {t("general.back_to_front_page")}
                </button>
            </Link>
        </div>
    </>;
}

function CancelledConfirmation(props: { subscription: Subscription, showSurvey: boolean }): React.ReactElement | null {
    const { t } = useTranslation();
    const [freeText, setFreeText] = React.useState("");
    const [reasons, setReasons] = React.useState<Set<string>>(new Set());
    if (props.subscription.closeDate === null) return null;

    const surveyMutation = useMutation({
        mutationFn: (vars: { reasons: Set<string>, freeText: string }) => {
            return axios.post<FairResponseTyped<string> | FairExceptionResponse>("subscription/" + props.subscription.subscriptionNumber + "/cancellationsurvey",
            {
                reasons: [...vars.reasons].join("|"),
                freeText: vars.freeText,
                timestamp: DateTime.now().toISO()
            },{
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });
        },
    });

    function reasonChanged(reason: string, value: boolean) {
        let newReasons = reasons;
        if (!value && reasons.has(reason)) {
            newReasons = new Set([...reasons].filter(x => x != reason));
            setReasons(newReasons);
        }
        if (value && !reasons.has(reason)) {
            newReasons = new Set([...reasons, reason]);
            setReasons(newReasons);
        }

        surveyMutation.mutate({ reasons: newReasons, freeText });
    }

    const cancellationDate = props.subscription.cancellationDate ?? props.subscription.closeDate;
    return <>
        <p>
            {t("cancel_subscription.subscription_is_cancelled", {
                date_cancelled: cancellationDate.toFormat("dd/MM-yyyy"),
                date_close: props.subscription.closeDate.toFormat("dd/MM-yyyy")
            })}
        </p>

        {props.showSurvey && <div className="block">
            <p>{t("cancel_subscription.consider_survey")}</p>
            <p>{t("cancel_subscription.receipt_at_bottom_of_page")}</p>
            <p>{t("cancel_subscription.whats_reason")}</p>
            
            <CheckBox value={reasons.has("time")}
                setValue={value => reasonChanged("time", value)}>
                {t("cancel_subscription.reason_time")}
            </CheckBox>
            <CheckBox value={reasons.has("motivation")}
                setValue={value => reasonChanged("motivation", value)}>
                {t("cancel_subscription.reason_motivation")}
            </CheckBox>
            <CheckBox value={reasons.has("cleaning")}
                setValue={value => reasonChanged("cleaning", value)}>
                {t("cancel_subscription.reason_cleaning")}
            </CheckBox>
            <CheckBox value={reasons.has("service")}
                setValue={value => reasonChanged("service", value)}>
                {t("cancel_subscription.reason_service")}
            </CheckBox>
            <CheckBox value={reasons.has("equipment")}
                setValue={value => reasonChanged("equipment", value)}>
                {t("cancel_subscription.reason_lack_of_equipment")}
            </CheckBox>
            <CheckBox value={reasons.has("economy")}
                setValue={value => reasonChanged("economy", value)}>
                {t("cancel_subscription.reason_economy")}
            </CheckBox>
            <CheckBox value={reasons.has("other")}
                setValue={value => reasonChanged("other", value)}>
                {t("cancel_subscription.reason_other")}
            </CheckBox>
            <TextArea value={freeText} setValue={setFreeText} onBlur={() => surveyMutation.mutate({ reasons, freeText })} />
            <div className="buttons">
                <button className="button is-primary"
                    onClick={() => surveyMutation.mutate({ reasons, freeText })}
                    disabled={surveyMutation.isLoading}>
                    {t("general.submit")}
                </button>
            </div>
        </div>}
        
        <article className="message">
            <div className="message-header">
                <p>{t("dashboard.cancellation_letter")}</p>
            </div>
            <div className="message-body">
                <CancellationLetter />
            </div>
        </article>
    </>;
}

function Cancellation (props: {onCancelled: () => void}): React.ReactElement {
    const lastPayment = DateTime.now().plus({ months: 1 }).startOf("month");
    const lastTraining = lastPayment.endOf("month");
    const subscription = useCurrentSubscription();
    const { t } = useTranslation();
    const query = useQueryClient();
    const [error, setError] = React.useState(false);

    const cancellationMutation = useMutation({
        mutationFn: () => {
            return axios.post<FairResponseTyped<string> | FairExceptionResponse>("subscription/" + subscription.subscriptionNumber + "/cancel", {}, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });
        },
        onSuccess: data => {
            if (data.data.success) {
                const letter = data.data.data;
                query.invalidateQueries(["user"]);
                query.setQueryData(["subscription", subscription.subscriptionNumber, "cancellation-letter"], () => letter);
                props.onCancelled();
            } else {
                setError(true);
            }
        },
        onError: () => {
            setError(true);
        },
    });

    if (error) {
        return <>
            <article className="message is-danger">
                <div className="message-body content">
                    <Trans i18nKey={"general.unknown_error_contact_us"}>
                        .<Link to={routes.messages()}>
                            <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                            <span>.</span>
                        </Link>
                    </Trans>
                </div>
            </article>
                
            <Link className="button" to={routes.dashboard()}>
                {t("general.back_to_front_page")}
            </Link>
        </>
    }

    return <>
        <p>
            {t("cancel_subscription.dates_warning", { lastPayment: lastPayment.toFormat("dd/MM-yyyy"), lastTraining: lastTraining.toFormat("dd/MM-yyyy") })}
        </p>
        <p>
            {t("cancel_subscription.cancellation_receipt_info")}
        </p>
        <p>
            <Trans i18nKey={"cancel_subscription.receipt_warning"}>
                .<span style={{fontWeight: "bold"}}>.</span>
            </Trans>
        </p>
        <p></p>
        <p>
            <Trans i18nKey={"cancel_subscription.contact_if_no_receipt"}>
                .<Link to={routes.messages()}>
                    <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span>.</span>
                </Link>
            </Trans>
        </p>
        <div className="buttons">
            <button className={"button is-danger"} disabled={cancellationMutation.isLoading}
                onClick={() => cancellationMutation.mutate()}>
                {t("cancel_subscription.i_wish_to_cancel")}
            </button>
            <Link to={routes.dashboard()}>
                <button className="button is-success" disabled={cancellationMutation.isLoading}>
                    {t("general.back_to_front_page")}
                </button>
            </Link>
        </div>
    </>
}

function TextArea(props: {value: string, setValue: (value: string) => void, onBlur: () => void}): React.ReactElement {
    const { t } = useTranslation();

    function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        props.setValue(e.target.value)
    }

    return <div className="field">
        <label className="label">{t("cancel_subscription.comments")}</label>
        <div className="control is-expanded">
            <textarea className="textarea"
                onBlur={props.onBlur}
                placeholder={t("cancel_subscription.please_elaborate")}
                value={props.value}
                onChange={onChange}></textarea>
        </div>
    </div>;
}

function CheckBox(props: { value: boolean, setValue: (value: boolean) => void, children: React.ReactNode }): React.ReactElement {
    return <div className="field">
        <label className="checkbox">
            <input type="checkbox"
                checked={props.value}
                onChange={e => props.setValue(e.target.checked)} />
            {" "}
            {props.children}
        </label>
    </div>;
}