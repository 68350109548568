import * as React from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../general/LoadingSpinner";
import { routes } from "../../lib/routes";

export function PageDirectAuthenticate(): React.ReactElement
{
    let { token } = useParams();
    token = token?.replaceAll('|', '.');

    window.localStorage.setItem("token", token ?? "");
    window.location.replace(routes.dashboard());

    return <LoadingSpinner />;
}