export const routes = {
    login: () => "/login",
    authenticate: (token: string) => "/authenticate/" + token + "/",
    dashboard: () => "/",
    booking: () => "/booking/",
    cancel: () => "/udmeld/",
    selectSubscription: () => "/vaelg-medlem/",
    personalTraining: () => "/personlig-traening/",
    updateCard: () => "/nyt-kort/",
    paymentHistory: () => "/betalingshistorik/",
    invoice_signed_in: (invoice: number | string) => "/betalingshistorik/" + invoice + "/",
    // contact: () => "/kontakt/",
    invoice: (invoiceId: string, accessToken: string) => "/faktura/" + invoiceId + "/" + accessToken + "/",
    messages: () => "/beskeder/",
    message: (messageId: string) => "/beskeder/" + messageId + "/"
};
