import * as React from "react";
import { Card } from "../ui/Card";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useCurrentSubscription } from "../../lib/authentication";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { Letter } from "../../lib/Models/MemberData";
import { PlaceholderTableRow } from "../general/PlaceholderTableRow";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../lib/routes";
import LoadingSpinner from "../general/LoadingSpinner";

export function PageMessage(): React.ReactElement {
    const { t } = useTranslation();
    return <div className="section container narrow">
        <Card title={t("messages.message")} content={false}>
            <div className="buttons">
                <Link className="button" to={routes.messages()}>{t("messages.back_to_messages")}</Link>
                <Link className="button" to={routes.dashboard()}>{t("general.back_to_front_page")}</Link>
            </div>
        </Card>
        <Card content={true}>
            <FairfitMessage />
        </Card>
    </div>;
}

function FairfitMessage(): React.ReactElement {
    const subscription = useCurrentSubscription();
    const { t } = useTranslation();

    const { messageId: messageKey } = useParams();
    if (messageKey === undefined) {
        return <p>{t("general.unknown_error")}</p>;
    }

    const messageType = messageKey.substring(0, messageKey.indexOf('-'));
    const messageId = messageKey.substring(messageType.length + 1);
   
    const { isLoading, data: letter } = useQuery<string | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "letter", messageType, messageId],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<string> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<string> | FairExceptionResponse>("/subscription/" + subscription.subscriptionNumber + "/letter/" + messageType + "/" + messageId, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            });

            if (!result.data.success) {
                return null;
            }

            const letter = result.data.data;
            return letter;
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (letter === null || letter === undefined) { 
        return <p>{t("general.unknown_error")}</p>;
    }

    return <div style={{ overflowX: "scroll" }}>
        <div className="content" style={{ wordBreak: "normal" }}dangerouslySetInnerHTML={{ __html: letter }}></div>
    </div>;
}