import * as React from "react";

export function PlaceholderTableRow(props: { columns: number, type: "td" | "th" }): React.ReactElement {
    return <tr>
        {Array(props.columns).fill(0).map((x, i) => <td key={i}>
            <div className="skeleton-lines">
                <div></div>
            </div>
        </td>)}
    </tr>;
}