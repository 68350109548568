import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../lib/routes";
import { signOut, useCurrentSubscription, useSetCurrentSubscription, useUser, useUserAllowNull } from "../lib/authentication";
import { useTranslation } from "react-i18next";
import { Subscription } from "../lib/Models/MemberData";
import { useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo.svg";
import ReactFlagsSelect from "react-flags-select";

export function SiteHeader(): React.ReactElement {
    const [expanded, setExpanded] = React.useState(false);

    return <header className="site-header">
        <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <img src={logo}
                            alt="Fair Fitness" width="150" height="80" />
                    </Link>
                    <a role="button"
                        className={"navbar-burger" + (expanded ? " is-active" : "")}
                        aria-label="menu"
                        aria-expanded={expanded ? "true" : "false"}
                        onClick={() => setExpanded(!expanded)}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <HeaderMenu expanded={expanded} />
            </div>
        </nav>
    </header>;
}

function HeaderMenu(props: {expanded: boolean}): React.ReactElement {
    const user = useUserAllowNull();
    const queryClient = useQueryClient();
    const { t, i18n } = useTranslation();

    if (user === null || user === "loading") {
        // Return an empty header if the user is currently signing in
        return <div className="navbar-menu">
            <div className="navbar-end">
                <a className="navbar-item" href="http://fairfit.dk">
                    {t("general.go_to_fairfitdk")}
                </a>
                <a className="navbar-item flag-select">
                    <ReactFlagsSelect
                        selected={i18n.language == "da" ? "DK" : "US"}
                        onSelect={(country: string) => country == "DK" ? setLanguage("da") : setLanguage("en")}
                        countries={["US", "DK"]}
                        showSelectedLabel={false}
                        showOptionLabel={false}
                        showSecondarySelectedLabel={false}
                    />
                </a>
            </div>
        </div>;
    }

    const currentSubno = localStorage.getItem("selectedSubscription");
    return <div className={"navbar-menu" + (props.expanded ? " is-active" : "")}>
        <div className="navbar-start">
            <Link to={currentSubno !== null
                        ? routes.dashboard() : routes.selectSubscription()} className="navbar-item">
                {t("general.my_membership")}
            </Link>
        </div>

        <div className="navbar-end">
            <UserDropDown subscriptions={user.subscriptions} currentSubscriptionNumber={null}/>
            <a className="navbar-item"
                onClick={() => {
                    signOut(queryClient);
                }}>
                {t("general.sign_out")}
            </a>
            <a className="navbar-item flag-select">
                <ReactFlagsSelect
                    selected={i18n.language == "da" ? "DK" : "US"}
                    onSelect={(country: string) => country == "DK" ? setLanguage("da") : setLanguage("en")}
                    countries={["US", "DK"]}
                    showSelectedLabel={false}
                    showOptionLabel={false}
                    showSecondarySelectedLabel={false}
                />
            </a>
        </div>
    </div>;

    function setLanguage(language: string) {
        localStorage.setItem("lng", language);
        i18n.changeLanguage(language);
    }
}

interface UserDropDownProps {
    subscriptions: { [subscriptionNumber: string]: Subscription };
    currentSubscriptionNumber: string | null;
}
function UserDropDown(props: UserDropDownProps) {
    const subscriptionNumbers = Object.keys(props.subscriptions);
    const { t } = useTranslation();
    const selected = useCurrentSubscription();
    const setCurrentSubscription = useSetCurrentSubscription();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const onMouseDown = (event: Event) => setOpen(false);
          
        window.addEventListener('click', onMouseDown);
        return () => {
            window.removeEventListener('click', onMouseDown);
        }
    }, []);

    if (subscriptionNumbers.length === 1) {
        return <div className="navbar-item">
            {props.subscriptions[subscriptionNumbers[0]].name} #{props.subscriptions[subscriptionNumbers[0]].subscriptionNumber}
        </div>;
    } else {
        return <div className={"navbar-item has-dropdown" + (open ? " is-active" : "")} onMouseLeave={() => setOpen(false)}>
            <Link className="navbar-link"
                to={routes.selectSubscription()}
                onMouseEnter={() => setOpen(true)}
                onClick={() => setOpen(true)}>
                <span className="icon">
                    <FontAwesomeIcon icon={faUser} />
                </span>&nbsp;
                { selected !== null
                    ? <div>
                        <div>{selected.name}</div>
                        <div><small>
                            #{selected.subscriptionNumber}
                        </small></div>
                    </div>
                    : t("general.no_subscription_selected")
                }
            </Link>
            <div className={"navbar-dropdown" + (!open ? " is-hidden-touch" : "")}>
                { subscriptionNumbers.map(subno => {
                    return <a className="navbar-item"
                        key={subno}
                        onClick={() => setCurrentSubscription(subno)}>
                        {props.subscriptions[subno].name} #{subno}
                    </a>;
                }) }
            </div>
        </div>;
    }
}
