import * as React from "react";
import { Card } from "../ui/Card";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { routes } from "../../lib/routes";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import { Payment as Payment, PaymentStatus } from "../../lib/Models/Payment";
import { useCurrentSubscription } from "../../lib/authentication";
import { DateTime } from "luxon";
import { PlaceholderTableRow } from "../general/PlaceholderTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFileInvoice, faReceipt } from "@fortawesome/free-solid-svg-icons";

export function PagePaymentOverview(): React.ReactElement {
    const { t } = useTranslation();
    const subscription = useCurrentSubscription();
    const { isLoading, data: payments } = useQuery<Payment[] | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "payment-history"],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<Payment[]> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<Payment[]> | FairExceptionResponse>("subscription/" + subscription.subscriptionNumber + "/paymenthistory", {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!result.data.success) {
                return null;
            }

            const payments: Payment[] = result.data.data.map(payment => ({
                ...payment,
                createdDateTime: DateTime.fromISO(payment.createdDateTime as any as string),
                capturedDateTime: payment.capturedDateTime !== null ? DateTime.fromISO(payment.capturedDateTime as any as string) : null,
            }));
            return payments;
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    return <div className="section container">
        <Card title={t("payment.payment_overview")} content={true}>
            <p>{t("payment.overview_of_payments")}</p>
            <p>{t("payment.invoice_available_day_after")}</p>
            <p>
                <Trans i18nKey={"payment.contact_us_if_questions"}>
                    .<Link to={routes.messages()}>
                        <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                        <span>.</span>
                    </Link>
                </Trans>
            </p>

            { payments !== null && payments !== undefined && <PaymentSummary payments={payments} /> }

            <div className="table-container">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>{t("general.date")}</th>
                            <th>{t("payment.date_completed")}</th>
                            <th>{t("payment.text")}</th>
                            <th>{t("general.amount")}</th>
                            <th>{t("general.status")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments === undefined || payments === null
                            ? <>
                                <PlaceholderTableRow columns={6} type={"td"} />
                                <PlaceholderTableRow columns={6} type={"td"} />
                                <PlaceholderTableRow columns={6} type={"td"} />
                                <PlaceholderTableRow columns={6} type={"td"} />
                                <PlaceholderTableRow columns={6} type={"td"} />
                            </>
                            : payments.map((payment: Payment, i) => <tr key={payment.reference}>
                            <td>{payment.createdDateTime.toFormat("dd/MM-yyyy")}</td>
                            <td>{payment.capturedDateTime?.toFormat("dd/MM-yyyy") ?? t("payment.not_completed")}</td>
                            <td>{payment.orderLines[0].text}</td>
                            <td>{(payment.amount / 100).toFixed(2)}</td>
                            <td>{getPaymentStatus(payment.status)}</td>
                            <td>
                                    {payment.invoiceId !== null && <Link to={routes.invoice_signed_in(payment.invoiceId)}>
                                        <span className="icon"><FontAwesomeIcon icon={faFileInvoice} /></span>
                                        {t("payment.invoice")}
                                    </Link>}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>

            <div className="buttons">
                <Link className="button" to={routes.dashboard()}>{ t("general.back_to_front_page")}</Link>
            </div>
        </Card>
    </div>;

    function getPaymentStatus(status: PaymentStatus) {
        switch(status) {
            case PaymentStatus.Captured:
                return t("payment.completed");
            case PaymentStatus.Failed:
                return t("payment.failed");
            case PaymentStatus.Pending:
                return t("payment.pending_payment");
            case PaymentStatus.Cancelled:
                return t("payment.cancelled");
        }
    }
}

function PaymentSummary(props: { payments: Payment[] }): React.ReactElement {
    const { t } = useTranslation();
    const amountDue = props.payments
        .filter(p => p.capturedDateTime === undefined)
        .reduce((a, b) => a + b.amount, 0);

    if (amountDue > 0) {
        return <div>
            <p className="title is-4">{t("payment.in_arrears")}</p>
            <p>{t("payment.missing_amount", { amount: (amountDue/100).toFixed(2) })}</p>
            <p>{t("payment.arrears_reasons")}</p>
            <p>{t("payment.new_card_change")}</p>
            <div className="buttons">
                <Link to={routes.updateCard()} className="button">{t("payment.change_card")}</Link>
            </div>
            <p>{t("payment.dunning_fees_if_unpaid")}</p>
        </div>;
    } else {
        return <div></div>;
    }
}
