import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import axios from "axios";
import Config from "../config";

import "./mystyles.scss";
import "../index.html";

import "./i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoadingSpinner from "./components/general/LoadingSpinner";

axios.defaults.baseURL = Config.FairControl.rootUrl;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 60
        }
    }
});

ReactDOM.render(
    <React.Suspense fallback={<LoadingSpinner />}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </QueryClientProvider>
    </React.Suspense>,
    document.getElementById("app"),
);
