import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useCurrentSubscription } from "../../lib/authentication";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FairExceptionResponse, FairResponseTyped } from "../../lib/Models/response";
import LoadingSpinner from "./LoadingSpinner";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function CancellationLetter(): React.ReactElement {
    const { t } = useTranslation();
    const subscription = useCurrentSubscription();
    const { isLoading, data: letter } = useQuery<string | null>({
        queryKey: ["subscription", subscription.subscriptionNumber, "cancellation-letter"],
        queryFn: async () => {
            let result: AxiosResponse<FairResponseTyped<string | null> | FairExceptionResponse, any>
            result = await axios.get<FairResponseTyped<string | null> | FairExceptionResponse>("subscription/" + subscription.subscriptionNumber + "/cancellationletter", {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!result.data.success) {
                return null;
            }

            return result.data.data;
        },
        retry: 0,
        onError: () => {
            return null;
        },
    });

    if (isLoading) {
        return <div className="skeleton-block"></div>;
    }

    if (letter === null || letter === undefined) {
        return <p>
            <Trans i18nKey="dashboard.missing_cancellation_letter">
                .<Link to={routes.messages()}>
                    <span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span>.</span>
                </Link>
            </Trans>
        </p>;
    }

    return <div className="content" dangerouslySetInnerHTML={{ __html: letter }}></div>;
}