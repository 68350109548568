import * as React from "react";
import { Route, Routes, useNavigate } from "react-router";
import { routes } from "../lib/routes";
import PageLogin from "./pages/PageLogin";
import { SiteHeader } from "./SiteHeader";
import { Dashboard as PageDashboard } from "./pages/PageDashboard";
import { PageUpdateCard } from "./pages/PageUpdateCard";
import { PagePaymentOverview } from "./pages/PagePaymentOverview";
import { PageSelectSubscription } from "./pages/PageSelectSubscription";
import { useCurrentSubscription, useUser, useUserAllowNull } from "../lib/authentication";
import LoadingSpinner from "./general/LoadingSpinner";
import { PageCancel } from "./pages/PageCancel";
import { PageInvoice } from "./pages/PageInvoice";
import { PageDirectAuthenticate } from "./pages/PageDirectAuthenticate";
import { PageMessages } from "./pages/PageMessages";
import { PageMessage } from "./pages/PageMessage";

export const selectedSubscriptionNumberContext = React.createContext<[string | null, React.Dispatch<React.SetStateAction<string | null>>] | null>(null);

export default function App(): React.ReactElement {
    const selectedSubscriptionNumber = React.useState<string | null>(localStorage.getItem("selectedSubscriptionNumber") ?? null);

    return <React.StrictMode>
        <selectedSubscriptionNumberContext.Provider value={selectedSubscriptionNumber}>
            { <SiteHeader /> }
            <Routes>
                <Route path={routes.login()} element={<PageLogin />} />
                <Route path={routes.authenticate(":token")} element={<PageDirectAuthenticate />} />
                <Route path={routes.selectSubscription()} element={<RequireLogin element={<PageSelectSubscription />} />} />
                <Route path={routes.dashboard()} element={<RequireSelectedSubscription element={<PageDashboard />} />} />
                <Route path={routes.updateCard()} element={<RequireSelectedSubscription element={<PageUpdateCard />} />} />
                <Route path={routes.paymentHistory()} element={<RequireSelectedSubscription element={<PagePaymentOverview />} />} />
                <Route path={routes.cancel()} element={<RequireSelectedSubscription element={<PageCancel />} />} />
                <Route path={routes.invoice_signed_in(":invoiceId")} element={<RequireSelectedSubscription element={<PageInvoice />} />} />
                <Route path={routes.invoice(":invoiceId", ":accessToken")} element={<PageInvoice />} />
                <Route path={routes.message(":messageId")} element={<RequireSelectedSubscription element={<PageMessage />} />} />
                <Route path={routes.messages()} element={<RequireSelectedSubscription element={<PageMessages />} />} />
                </Routes>
        </selectedSubscriptionNumberContext.Provider>
    </React.StrictMode>;
}

function RequireLogin(props: { element: React.JSX.Element }): React.ReactElement {
    const user = useUserAllowNull();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (user === null) {
            navigate(routes.login());
        }
    }, [user]);

    if (user === "loading" || user === null) {
        return <LoadingSpinner />;
    }
    return props.element;
}

function RequireSelectedSubscription(props: { element: React.JSX.Element }): React.ReactElement | null {
    function InnerComponent(props: { element: React.JSX.Element }) {
        const subscription = useCurrentSubscription();
        const navigate = useNavigate();
        React.useEffect(() => {
            if (subscription === null) {
                navigate(routes.selectSubscription());
            }
        }, [subscription]);

        if (subscription === null) {
            return null;
        }
        return props.element;
    }

    return <RequireLogin element={<InnerComponent element={props.element} />} />
}